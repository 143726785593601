<!-- 联系客服 -->
<template>
  <div class="kefu">
    <div class="box">
      <div>
        <img src="@/assets/images/kefu_left.png" alt="">
        <p>扫码联系客服</p>
      </div>
      <div>
        <img src="@/assets/images/kefu_right.png" alt="">
        <p>小程序</p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {

  },
  created() {

  }
}
</script>

<style lang="less" scoped>
.kefu {
  height: 100%;

  .box {
    padding-top: 150px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    p {
      text-align: center;
      font-weight: 500;
      font-size: 17px;
      color: #000;
    }
  }
}
</style>
